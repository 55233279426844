/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/foundation-sites@6.7.5/dist/js/plugins/foundation.core.min.js
 * - /npm/foundation-sites@6.7.5/dist/js/plugins/foundation.util.box.min.js
 * - /npm/foundation-sites@6.7.5/dist/js/plugins/foundation.util.imageLoader.min.js
 * - /npm/foundation-sites@6.7.5/dist/js/plugins/foundation.util.keyboard.min.js
 * - /npm/foundation-sites@6.7.5/dist/js/plugins/foundation.util.mediaQuery.min.js
 * - /npm/foundation-sites@6.7.5/dist/js/plugins/foundation.util.motion.min.js
 * - /npm/foundation-sites@6.7.5/dist/js/plugins/foundation.util.nest.min.js
 * - /npm/foundation-sites@6.7.5/dist/js/plugins/foundation.util.touch.min.js
 * - /npm/foundation-sites@6.7.5/dist/js/plugins/foundation.util.triggers.min.js
 * - /npm/foundation-sites@6.7.5/dist/js/plugins/foundation.dropdownMenu.min.js
 * - /npm/foundation-sites@6.7.5/dist/js/plugins/foundation.offcanvas.min.js
 * - /npm/foundation-sites@6.7.5/dist/js/plugins/foundation.reveal.min.js
 * - /npm/foundation-sites@6.7.5/dist/js/plugins/foundation.tabs.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
